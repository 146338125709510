import {Component, Input, OnInit} from '@angular/core';
import {FileUploadService} from "./file-upload.service";
import {ElementData} from "../generic-element/elementData";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {GenericElementComponent} from "../generic-element/generic-element.component";

@Component({
  selector: 'app-post-creator',
  standalone: true,
  imports: [
    CommonModule, FormsModule, GenericElementComponent
  ],
  templateUrl: './post-creator.component.html',
  styleUrl: './post-creator.component.scss'
})
export class PostCreatorComponent implements OnInit{

  markdownContent = '';
  @Input() elements: ElementData[] = [];

  constructor(private fileUploadService: FileUploadService) {
  }

  ngOnInit(): void {
    if (this.elements.length > 0) {
      this.markdownContent = this.generateMarkdownFromElements(this.elements);
    }
  }

  addVideo(): void {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'video/*';
    input.onchange = (event: any) => {
      if (event.target.files && event.target.files.length > 0) {
        const selectedFile = event.target.files[0];
        this.uploadVideo(selectedFile);
      }
    };
    input.click();
  }

  uploadVideo(file: File): void {
    this.fileUploadService.uploadFile(file).then((response) => {
      const videoLink = `[video]: # (${response.url})`;
      this.insertAtCursor(videoLink);
      this.updatePreview();
    });
  }

  insertAtCursor(text: string): void {
    const textarea: HTMLTextAreaElement | null = document.querySelector('#content');
    if (textarea) {
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      this.markdownContent = this.markdownContent.slice(0, start) + text + this.markdownContent.slice(end);
      textarea.selectionStart = textarea.selectionEnd = start + text.length;
    }
  }

  updatePreview(): void {
    const lines = this.markdownContent.split('\n');
    this.elements = [];
    let currentMarkdown = '';

    lines.forEach(line => {
      const videoMatch = line.match(/\[video\]: # \((.+)\)/);
      if (videoMatch) {
        if (currentMarkdown) {
          this.elements.push({kind: 'markdown', content: currentMarkdown});
          currentMarkdown = '';
        }
        this.elements.push({kind: 'video', content: videoMatch[1]});
      } else {
        currentMarkdown += (currentMarkdown ? '\n' : '') + line;
      }
    });

    if (currentMarkdown) {
      this.elements.push({kind: 'markdown', content: currentMarkdown});
    }
  }

  generateMarkdownFromElements(elements: ElementData[]): string {
    let markdown = '';
    elements.forEach(element => {
      if (element.kind === 'video') {
        markdown += `[video]: # (${element.content})\n`;
      } else if (element.kind === 'markdown') {
        markdown += `${element.content}\n`;
      }
    });
    return markdown.trim();
  }

  submitPost(): void {
    console.log('Post Submitted:', this.elements);
    // Here you would typically send the elements array to your backend API.
  }
}
