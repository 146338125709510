import {Component} from '@angular/core';
import {environment} from "../../environments/environment";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-staging-banner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './staging-banner.component.html',
  styleUrl: './staging-banner.component.scss'
})
export class StagingBannerComponent {

  protected readonly environment = environment;
  protected readonly actualUrl = 'https://cosmic-energy.keldysh.io/';

}
