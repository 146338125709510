<div class="container">
  <div class="row">
    <div class="col-md-6">
      <h2>Create Your Post</h2>
      <form>
        <div class="form-group">
          <label for="content">Content</label>
          <textarea id="content" class="form-control" [(ngModel)]="markdownContent" name="content" rows="10" required></textarea>
        </div>
        <button type="button" class="btn btn-primary" (click)="addVideo()">Add Video</button>
        <button type="button" class="btn btn-secondary" (click)="updatePreview()">Update Preview</button>
      </form>
    </div>
    <div class="col-md-6">
      <h3>Live Preview</h3>
      <div class="container row">
        <div class="col-lg-12">
          <app-generic-element *ngFor="let element of elements" [data]="element"></app-generic-element>
        </div>
      </div>
    </div>
  </div>
  <button type="submit" class="btn btn-success" (click)="submitPost()">Submit Post</button>
</div>
