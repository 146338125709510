import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {environment} from "../../environments/environment";
import {FetchService} from "../fetch.service";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private http: HttpClient, private fetchService: FetchService) {
  }

  async uploadFileMock(file: File): Promise<{ url: string }> {
    return new Promise((resolve) => {
      resolve({url: 'http://whatever'});
    })
  }

  async uploadFile(file: File): Promise<{ url: string }> {
    const response = await this.fetchService.fetch(environment.apiPath + '/files', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream',
        'X-File-Name': encodeURIComponent(file.name),
        'X-File-Type': file.type
      },
      body: file
    });

    if (!response.ok) {
      throw new Error('File upload failed');
    }

    // Read the response as text
    const result = await response.text();
    return {url: result};
  }
}
