<form [formGroup]="credentialsForm" (ngSubmit)="onSubmit()" class="container mt-3">
  <div class="form-group pt-3">
    <label for="email">Email:</label>
    <input id="email" type="email" formControlName="email" required class="form-control">
  </div>

  <!-- Uncomment if sign-up mode is required -->
  <!-- <div *ngIf="isSignUpMode" class="form-group">
    <label for="firstName">First Name:</label>
    <input id="firstName" type="text" formControlName="firstName" required class="form-control">
  </div>
  <div *ngIf="isSignUpMode" class="form-group">
    <label for="name">Name:</label>
    <input id="name" type="text" formControlName="name" required class="form-control">
  </div> -->

  <div class="form-group pt-3">
    <label for="password">Password:</label>
    <input id="password" type="password" formControlName="password" required class="form-control">
    <div class="d-flex justify-content-end">
      <button *ngIf="!isSignUpMode" type="reset" class="btn btn-link" (click)="resetPassword()">Forgot Password?</button>
    </div>
  </div>

  <div class="d-flex justify-content-between pt-3">
    <button type="button" class="btn btn-outline-secondary" (click)="toggleMode()">
      {{ isSignUpMode ? 'Switch to Log In' : 'Switch to Sign Up' }}
    </button>
    <button type="submit" class="btn btn-primary" [disabled]="!credentialsForm.valid">
      {{ isSignUpMode ? 'Sign Up' : 'Log In' }}
    </button>
  </div>

  <div *ngIf="signupSuccess" class="alert alert-success animated fadeIn">
    Before login, please check your email to finish registration.
  </div>

  <div *ngIf="signupError" class="alert alert-danger animated shake">
    {{ signupError }}
  </div>

  <div *ngIf="loginError" class="alert alert-danger animated shake">
    {{ loginError }}
  </div>
</form>
