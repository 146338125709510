import {Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {environment} from "../../environments/environment";
import firebase from "firebase/compat";
import FirebaseIdToken = firebase.FirebaseIdToken;

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private httpClient: HttpClient) {
  }

  public create(email: string, password: string) {
    return this.httpClient.post(environment.apiPath + "/customer/create", {email: email, password: password});
  }

  jwt() {
    this.httpClient.get<FirebaseIdToken>(environment.apiPath + '/customer/test-jwt').subscribe({
      next: (value: FirebaseIdToken) => console.log("Success: " + value.email),
      error: (error: HttpErrorResponse) => {
        // Check if the error is an instance of HttpErrorResponse
        // Log the error status and message
        console.log(`Error: Status ${error.status} - ${error.statusText}`);
        console.log('Error Body:', this.parseErrorBody(error.error));
      },
    })
  }

  private parseErrorBody(errorBody: any): any {
    try {
      return JSON.parse(errorBody);
    } catch {
      return errorBody;
    }
  }
}
