<div>
  <h2>Course Elements</h2>
  <div class="container row">
    <div class="col-lg-12">
      <app-generic-element [data]="textContent"></app-generic-element>
      <app-generic-element [data]="markdownContent"></app-generic-element>
      <app-generic-element [data]="videoContent"></app-generic-element>
      <!--  <div *ngFor="let element of elements">-->

      <!-- Add more conditions for different kinds -->
      <!--  </div>-->
    </div>
  </div>
</div>
