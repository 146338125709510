import { Injectable } from '@angular/core';
import {LoadingService} from "./loading/loading.service";

@Injectable({
  providedIn: 'root'
})
export class FetchService {

  constructor(private loadingService: LoadingService) {}

  async fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
    this.loadingService.show();
    try {
      const response = await fetch(input, init);
      return response;
    } finally {
      this.loadingService.hide();
    }
  }
}
