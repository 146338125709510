import {Component} from '@angular/core';
import {CommonModule} from "@angular/common";
import {GenericElementComponent} from "../generic-element/generic-element.component";

@Component({
  selector: 'app-post',
  standalone: true,
  imports: [CommonModule, GenericElementComponent],
  templateUrl: './post.component.html',
  styleUrl: './post.component.scss'
})
export class PostComponent {

  markdownContent = {
    content: `# Heading 1
## Heading 2

This is normal Text.

   - Bullet 1
   - Bullet 2

`,
    kind: 'markdown'
  }

  videoContent = {
    content: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
    kind: 'video'
  }

  textContent = {
    content: 'Regular text',
    kind: 'text'
  }

  plan = {
    content: `
    Create Category, e.g.: course,
    create feed:
       optional category, e.g. course  ... all categories will create a nav,
       optional subcategory, e.g. mental health ... subnav,
       sorting: asc/desc
       kind:
          - public (static content available for anyone, e.g. announcements, news, offerings)
          - all-subscribed (static content available for any subscribed user, e.g. introductions)
          - pay-per-post
          - specific:
              options:
                - purchase (con only be purchased as a whole, one-time payment)


    `,
    kind: 'markdown'
  }

}
