import {Routes} from '@angular/router';
import {FeaturesComponent} from "./features/features.component";
import {PlansComponent} from "./plans/plans.component";
import {authenticationGuard} from "./authentication.guard";
import {LegalComponent} from "./legal/legal.component";
import {PostComponent} from "./post/post.component";
import {PostCreatorComponent} from "./post-creator/post-creator.component";
import {FeedCreatorViewComponent} from "./feed-creator-view/feed-creator-view.component";

export const routes: Routes = [
  {path: '', component: PostComponent},
  {path: 'creator', component: PostCreatorComponent},
  {path: 'feed-creator', component: FeedCreatorViewComponent},
  {path: 'features', component: FeaturesComponent},
  {path: 'plans', component: PlansComponent},
  {path: 'legal', component: LegalComponent},
  {
    path: 'secure',
    canActivate: [authenticationGuard],
    loadChildren: () => import('./secure/secure.routes').then(m => m.routes)
  }
];
