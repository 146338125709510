import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {AsyncPipe, NgClass, NgIf, NgOptimizedImage} from "@angular/common";
import {filter} from "rxjs";
import {LoginComponent} from "./login/login.component";
import {LoginModalService} from "./login/login-modal/login-modal.service";
import {FirebaseAuthenticationService} from "./firebase/firebase-authentication.service";
import {LoginModalComponent} from "./login/login-modal/login-modal.component";
import {CheckoutModalComponent} from "./plans/checkout-modal/checkout-modal.component";
import {LoadingComponent} from "./loading/loading.component";
import {ActionConfirmationModalComponent} from "./action-confirmation-modal/action-confirmation-modal.component";
import {StagingBannerComponent} from "./staging-banner/staging-banner.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, RouterLink, RouterLinkActive, NgClass, NgIf, LoginComponent, AsyncPipe, LoginModalComponent, CheckoutModalComponent, LoadingComponent, ActionConfirmationModalComponent, StagingBannerComponent, NgOptimizedImage],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  title = 'cosmic-energy';

  navbarCollapsed = true;
  showFooter = true;

  constructor(
    private router: Router,
    protected loginModalService: LoginModalService,
    protected authenticationService: FirebaseAuthenticationService
  ) {
    this.router
      .events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        // Here, `event` is strictly typed as NavigationEnd, avoiding the type mismatch error
        this.showFooter = (<NavigationEnd>event).urlAfterRedirects !== '/';
      });
  }

  pageLoaded = false;

  ngOnInit(): void {
    setTimeout(() => {
      this.pageLoaded = true;
    }, 0);

    // const app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);
  }


  toggleCollapsed(): void {
    this.navbarCollapsed = !this.navbarCollapsed;
  }

  protected readonly window = window;
}
