import {Component} from '@angular/core';
import {ElementData} from "../generic-element/elementData";
import {PostCreatorComponent} from "../post-creator/post-creator.component";

@Component({
  selector: 'app-feed-creator-view',
  standalone: true,
  imports: [
    PostCreatorComponent
  ],
  templateUrl: './feed-creator-view.component.html',
  styleUrl: './feed-creator-view.component.scss'
})
export class FeedCreatorViewComponent {

  elements: ElementData[] = [
    {
      content: "# Heading 1\n\n## Subheading 1\n\nA little bit of random text\n\n  - bullet point 1\n  - bullet point 2\n",
      kind: "markdown"
    },
    {
      content: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      kind: "video"
    },
    {
      content: "## Subheading 2\n\nAnd some more text.\n",
      kind: "markdown"
    },
    {
      content: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
      kind: "video"
    }
  ]

}
