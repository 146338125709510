<div *ngIf="data.kind === 'text'">
  <p [innerHTML]="data.content"></p>
</div>
<div *ngIf="data.kind === 'markdown'">
  <markdown [data]="data.content"></markdown>
</div>
<div *ngIf="data.kind === 'video'" style="margin: 2vh">
    <video height="auto" width="100%" controls controlsList="nodownload noplaybackrate" disablePictureInPicture>
<!--  <video min-width="500" height="auto" controls controlslist="play timeline volume" >-->
    <source [src]="data.content" type="video/mp4">
    Your browser does not support the video tag.
  </video>
</div>
