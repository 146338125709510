<div id="legal" #legal class="py-5">
  <h1 class="display-5 text-center py-5">Licenses</h1>
  <div class="py-3"></div>
  <section class="section" [id]=properties.goldContractAnchor>
    <div class="container py-5 text-area">
      <div class="row align-items-center">
        <div class="col-lg-6 offset-lg-3">
          <div class="p-5">
            <h3 class="py-5">COSMIC ENERGY - GOLD PLAN</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="py-3"></div>
  <section class="section" [id]=properties.platinumContractAnchor>
    <div class="container py-5 text-area">
      <div class="row align-items-center">
        <div class="col-lg-6 offset-lg-3">
          <div class="p-5">
            <h3 class="py-5">COSMIC ENERGY - PLATINUM PLAN</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="py-3"></div>
  <section class="section" [id]=properties.diamondContractAnchor>
    <div class="container py-5 text-area">
      <div class="row align-items-center">
        <div class="col-lg-6 offset-lg-3">
          <div class="p-5">
            <h3 class="py-5">COSMIC ENERGY - DIAMOND PLAN</h3>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
