import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import { HttpErrorResponse } from "@angular/common/http";
import {FirebaseAuthenticationService} from "../firebase/firebase-authentication.service";
import {CustomerService} from "./customer.service";
import firebase from "firebase/compat";
import {CommonModule} from "@angular/common";
import {LoadingService} from "../loading/loading.service";
import FirebaseError = firebase.FirebaseError;

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  @Input() isSignUpMode = false;
  @Output() loginSuccess = new EventEmitter<void>();

  credentialsForm: FormGroup;

  signupSuccess = false;
  signupError!: string;
  loginError!: string;

  constructor(
    private authenticationService: FirebaseAuthenticationService,
    private customerService: CustomerService,
    private loadingService: LoadingService
  ) {
    this.credentialsForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      // firstName: new FormControl({value: '', disabled: this.isSignUpMode}, [Validators.required]),
      // name: new FormControl({value: '', disabled: this.isSignUpMode}, [Validators.required]),
      password: new FormControl(
        '',
        [
          Validators.required,
          Validators.minLength(6)
        ])
    });
  }

  private initMessages() {
    this.signupSuccess = false;
    this.signupError = "";
    this.loginError = "";
  }

  onSubmit() {
    this.initMessages();
    if (this.credentialsForm.valid) {
      if (this.isSignUpMode) {
        this.signUp();
      } else {
        this.login();
      }
    }
  }

  toggleMode() {
    this.isSignUpMode = !this.isSignUpMode; // Toggle the mode
  }

  signUp() {
    if (this.credentialsForm.valid) {
      const {email, password} = this.credentialsForm.value;

      this.customerService
        .create(email, password)
        .subscribe({
          next: () => {
            console.log('Sign Up Successful');
            this.signupSuccess = true;
            this.isSignUpMode = false;
          },
          error: (error: HttpErrorResponse) => {
            console.log(error.error)
            this.signupError = error.error;
          }
        })
    }
  }

  login() {
    if (this.credentialsForm.valid) {
      const {email, password} = this.credentialsForm.value;

      this.loadingService.show()

      this.authenticationService
        .login(email, password)
        .then(() => {
          console.log('Login Successful');
          this.loginSuccess.emit();
        })
        .catch((error: FirebaseError) => {
          console.log("Caught error")
          console.error(error.code);
          this.loginError = error.code.toString();
        })
        .finally(() => this.loadingService.hide());
    }
  }

  resetPassword() {
      const {email} = this.credentialsForm.value;

      this.loadingService.show()

      this.authenticationService
        .resetPassword(email)
        .then(() => {
          console.log('Password reset initialized');
          this.loginSuccess.emit();
        })
        .catch((error: FirebaseError) => {
          console.log("Caught error")
          console.error(error.code);
          this.loginError = error.code.toString();
        })
        .finally(() => this.loadingService.hide());
  }

  // logout() {
  //   this.authenticationService
  //     .logout()
  //     .then(() => {
  //       console.log('Logout Successful');
  //     })
  //     .catch((error: FirebaseError) => {
  //       console.error(error.message);
  //     })
  //     .then(() => this.router
  //       .navigate(['/'])
  //       .then(() => {
  //         window.location.reload();
  //       })
  //     );
  // }

  // private parseErrorBody(errorBody: any): any {
  //   try {
  //     return JSON.parse(errorBody);
  //   } catch {
  //     return errorBody;
  //   }
  // }
}
