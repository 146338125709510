import {ApplicationConfig} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptors} from "@angular/common/http";
import {FirebaseAuthenticatorInterceptor} from "./firebase/firebase-authenticator.interceptor";
import {loadingInterceptor} from "./loading/loading.interceptor";
import {provideMarkdown} from "ngx-markdown";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    // importProvidersFrom(HttpClientModule),
    {provide: HTTP_INTERCEPTORS, useClass: FirebaseAuthenticatorInterceptor, multi: true},
    provideHttpClient(withInterceptors([loadingInterceptor])),
    provideMarkdown()
  ],
};
