import {Component, Input} from '@angular/core';
import {NgIf} from "@angular/common";
import {MarkdownComponent} from "ngx-markdown";
import {ElementData} from "./elementData";

@Component({
  selector: 'app-generic-element',
  standalone: true,
  imports: [
    NgIf,
    MarkdownComponent
  ],
  templateUrl: './generic-element.component.html',
  styleUrl: './generic-element.component.scss'
})
export class GenericElementComponent {

  @Input() data!: ElementData;

}
